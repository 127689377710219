import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import Button from "@material-ui/core/Button";
import { useAuth } from "./../util/auth";
import { useAuth0 } from '@auth0/auth0-react';


const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 60,
  },
  row: {
    // Reverse every other row
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
    },

    // Spacing between rows
    "&:not(:last-child)": {
      // marginBottom: `${theme.spacing(3)}px`,
    },
  },
  figure: {
    // maxWidth: 300,
    // margin: "3em auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
    borderRadius: "25%",
    marginTop: '1em',
    transform: "scale(1.1)",
  },
  stepImage: {
    height: "4em",
  },
  headerText: {
    background: '-webkit-linear-gradient(rgb(76, 253, 188), rgb(17, 172, 129))',
    "-webkit-background-clip": 'text',
    "-webkit-text-fill-color": 'transparent'
  },
  featureText: {
    background: '-webkit-linear-gradient(rgb(76, 213, 188), rgb(17, 172, 129))',
    "-webkit-background-clip": 'text',
    "-webkit-text-fill-color": 'transparent'
  }
}));

function FeaturesSection(props) {
  const classes = useStyles();
  const theme = useTheme();

  const auth = useAuth();
  const {loginWithRedirect} = useAuth0();

  const items = [
    {
      title: "Buy Cryptocurrencies with Ease",
      description:
        "At Crypto Wall Street, we make buying Bitcoin (BTC) and Ethereum (ETH) simple and convenient. Our platform allows you to purchase these popular cryptocurrencies using Australian Dollars (AUD).",
      image: "./landing_2.png",
    },
    {
      title: "Transparent and Competitive Buying/Selling Fees",
      description:
        "We strive to provide fair and competitive fee structures to ensure a seamless user experience. Our buying and selling fees are set at a competitive rate of 0.80% of your transaction value. These fees are included in the total cost, so you see the exact amount you'll pay upfront.",
      image: "./landing_buying_eth_2.png",
    },
    {
      title: "Auto Buy",
      description:
        "Simplify Your Crypto Investments. Our new Auto Buy feature enables you to set up recurring purchases of cryptocurrencies, effortlessly building your digital asset portfolio over time. Set it up once and let it handle your purchases automatically, giving you more time to focus on what matters most.",
      image: "./landing_autobuy1.png",
    },
    {
      title: "Securely Store Your Assets",
      description:
        "We prioritize your ownership and control over your crypto assets. You have the option to withdraw your purchased BTC and ETH to your personal wallet for enhanced security. We highly recommend this practice as it gives you full control and ownership of your cryptocurrencies. As the first exchange encouraging users to not keep their coins on the exchange, we believe in promoting self-custody and empowering you to safeguard your assets.",
      image: "./landing_withdraw_eth.png",
    },
  ];

  const whyItems = [
    {
      title: "🔒 Empowering Self-Custody",
      description:
        "While many other exchanges encourage you to keep your coins on their platform, we're different at Crypto Wall Street. We empower you to take control by advocating for the withdrawal of your coins to your personal wallet. Your ownership, your security – it's our priority.",
    },
    {
      title: "🌟 User-Friendly Experience",
      description:
        "Navigating the crypto world should be seamless. Our platform offers a user-friendly interface designed to make your crypto journey enjoyable and accessible, whether you're a seasoned investor or new to the game.",
    },
    {
      title: "🌎 Freedom from Day Trading",
      description:
        "At Crypto Wall Street, we believe in empowering long-term strategies. While many exchanges focus on fast-paced day trading, we take a different approach. We don't offer day trading services, freeing you from the complexities and volatility of constant market monitoring. Instead, we champion consistent, hassle-free crypto investments that allow you to grow your portfolio over time. Experience financial freedom without the stress, and discover the power of secure, self-directed crypto ownership.",
    },
    {
      title: "🔄 Auto Buy Crypto",
      description:
        "Our Auto Buy feature lets you set up recurring purchases of cryptocurrencies, following a strategy that data suggests to be supreme in the crypto space. With this approach, you can make consistent investments over the long term.",
    },
    {
      title: "🚫 No Margin Trading Services",
      description:
        "We prioritize your financial well-being. Unlike other platforms, we don't offer margin trading services. This decision safeguards your capital, prevents unnecessary risks, and gives you more time to focus on your financial goals.",
    },
    {
      title: "⚖️ Transparent and Fair Fees",
      description:
        "Our fee structure is designed with fairness and transparency in mind. You'll always know what you're paying, without hidden surprises.",
    },
    {
      title: "💬 Dedicated Support",
      description:
        "Have questions or need assistance? Our support team is here for you. We provide reliable and responsive customer service to ensure a smooth and satisfying experience.",
    },
  ];

  const startSteps = [
    {
      title: "1. Create",
      description: "Sign up and verify your free account.",
      image: 'step1.png'
    },
    {
      title: "2. Deposit",
      description: "Send AUD to your wallet using our secure deposit methods.",
      image: 'step2.png'
    },
    {
      title: "3. Start your crypto journey",
      description: "You’re all set to buy crypto.",
      image: 'step3.png'
    },
  ]

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          titleColor={true}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.itemsContainer}
        >
          {items.map((item, index) => (
            <Grid
              className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={4}
              key={index}
            >
              <Grid item={true} xs={12} md={6}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true} className={classes.featureText}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <figure className={classes.figure}>
                  <img
                    src={item.image}
                    alt={item.title}
                    className={classes.image}
                  />
                </figure>
              </Grid>
            </Grid>
          ))}<br/>
          <Typography variant="h5" gutterBottom={true} align="center">
            Join us today and experience the simplicity and security of buying cryptocurrencies on Crypto Wall Street!
          </Typography><br/><br/><br/><br/>
          <Typography variant="h4" gutterBottom={true} align="center" className={classes.headerText}>
             Why Choose Crypto Wall Street?
          </Typography><br/><br/>
          <Typography variant="h5" gutterBottom={true} align="center">
          At Crypto Wall Street, we're not your typical crypto exchange – we're your mates on your digital asset adventure. Here's why you should choose us:
          </Typography><br/>
          {whyItems.map((item, index) => (
            <Grid
              // className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={8}
              key={index}
            >
              <Grid item={true} xs={12} md={12}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.description}
                  </Typography><br/>
                </Box>
              </Grid>
            </Grid>
          ))}<br/><br/>
          <Typography variant="h5" gutterBottom={true} align="center">
            Choose Crypto Wall Street and embark on an exciting journey towards financial empowerment and digital asset ownership. Your crypto adventure begins here.
          </Typography><br /><br /><br/>

          <Typography variant="h4" gutterBottom={true} align="center" className={classes.headerText}>
          Start your crypto journey in 3 easy steps
          </Typography><br/>
          <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={5}>
                {startSteps.map((item, index) => (
                  <Grid key={item} item xs={4}>
                      <Box
                        textAlign={{
                          xs: "center",
                          md: "center"
                        }}
                      >
                        <img
                          src={item.image}
                          alt={item.title}
                          className={classes.stepImage}
                        />
                        <Typography variant="h5" gutterBottom={true}>
                          {item.title}
                        </Typography>
                        <Typography variant="subtitle1">
                          {item.description}
                        </Typography><br />
                      </Box>
                  </Grid>
                ))}
              </Grid><br/>
              <Typography variant="h5" gutterBottom={true} align="center">
                <Button
                  component={Link}
                  to={"/dashboard"}
                  variant="contained"
                  size="large"
                  color={"primary"}
                  style={theme.buttonStyle}
                  onClick={async () => {
                    // if (auth.user === false) {
                    //   await loginWithRedirect({
                    //     screen_hint: 'signup',
                    //     redirect_uri: window.location.origin
                    //   });
                    // }
                  }}
                >
                  {(auth.user === false ? "Create free account" : "Your dashboard")}
                </Button>
              </Typography><br />
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
