import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import TokenListings from "./../components/TokenListings";
import Typography from "@material-ui/core/Typography";

function HomePage(props) {
  return (
    <>
      <Meta />
      
      <TokenListings />
      {/* <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="The Crypto Boulevard, where your keys reign supreme."
        subtitle="Welcome to Crypto Wall Street, where we make crypto ownership a breeze! Our mission is to empower you to take control of your digital assets with confidence and security. As trailblazers in self-custody, we encourage you to withdraw your coins after using our convenient buying service. After all, your coins deserve a secure wallet tailored just for them!"
        image="./landing_1_v3.png"
        buttonText="Get Started"
        buttonColor="primary"
        buttonPath="/dashboard"
      />
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Unlock the Crypto Boulevard"
        titleColor={true}
        subtitle="Essential Features for Navigating the Crypto Block"
      /> */}
    </>
  );
}

export default HomePage;
