import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    margin: "0 auto",
    maxWidth: 370,
    display: "block",
    height: "auto",
    width: "100%",
    marginTop: '1.5em',
    borderRadius: "35%",
    transform: "scale(1.5)",
  },
}));

function HeroSection(props) {
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();
  const {loginWithRedirect} = useAuth0();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={6}>
          <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <Box textAlign={{ xs: "center", md: "left" }}>
              <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                size={4}
              />
              <Button
                component={Link}
                to={props.buttonPath}
                variant="contained"
                size="large"
                color={props.buttonColor}
                style={theme.buttonStyle}
                onClick={async ()=> {
                  // if (auth.user === false) {
                  //   await loginWithRedirect({
                  //       screen_hint: 'signup',
                  //       redirect_uri: window.location.origin
                  //   });
                  // }
                }}
              >
                {(auth.user === false ? props.buttonText : "Your dashboard")}
              </Button>
            </Box>
          </Grid>
          <Grid item={true} xs={12} md={true} >
            <figure>
              <img
                src={props.image}
                alt="illustration"
                className={classes.image}
              />
            </figure>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default HeroSection;
